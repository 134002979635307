@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg.star-svg {
  display: inline;
}

.star-svg {
  width: 32px;
  height: 32px;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media only screen and (min-width: 400px) {
  .star-svg {
    width: 35px;
    height: 35px;
  }
}

/* 640px = sm in TailwindCSS */
@media only screen and (min-width: 640px) {
  .star-svg {
    width: 40px;
    height: 40px;
  }
}
